import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { getToken } from '../../utils/auth'

const GuestRoute = ({ component: Component, ...rest }) => {
    const token = getToken()

    if (token) {
        return (
            <Redirect
                to={{
                    pathname: '/home',
                }}
            />
        )
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                return <Component {...props} />
            }}
        />
    )
}

export default GuestRoute
