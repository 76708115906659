import React from 'react'
import Route from './Routes'
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router } from 'react-router-dom'
import BasicLayout from './layout/BasicLayout'
import './i18n'
import 'assets/css/public.css'
import 'assets/css/index.css'
import 'assets/css/app.scss'

const history = createBrowserHistory()

function App() {
    return (
        <Router history={history}>
            <BasicLayout>
                <Route></Route>
            </BasicLayout>
        </Router>
    )
}

export default App
