import axios from 'axios'
import { getCustomer } from './customer'
import { getToken } from './auth'

const instance = axios.create({
    baseURL:
        process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_API_HOST || null
            : 'https://api.memberline.com.tw',
    headers: {
        'X-Customer-Account': sessionStorage.getItem('customer'),
        'Content-Type': 'application/json',
    },
})

instance.interceptors.request.use(function (config) {
    const token = getToken()
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    config.headers['X-Customer-Account'] = getCustomer()
    return config
})

export default instance
