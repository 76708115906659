import React, { createContext } from 'react';

import { getBranch, setBranch } from '../utils/customer';

export const BranchContext = createContext();

export const { Provider, Consumer } = BranchContext;

const BranchProvider = ({ children }) => {
    const branch = getBranch();

    return (
        <Provider
            value={{
                branch,
                setBranch,
            }}
        >
            {children}
        </Provider>
    );
};

export default BranchProvider;
