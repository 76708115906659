export const getSubdomain = () => {
    let host = window.location.host
    let parts = host.split('.')
    // If we get more than 3 parts, then we have a subdomain
    // INFO: This could be 4, if you have a co.uk TLD or something like that.
    if (parts.length >= 3) {
        return parts[0]
    }
    return
}
