import React, { createContext } from 'react'

import { getSubdomain } from '../utils/path'
import { setCustomer } from '../utils/customer'

export const CustomerContext = createContext()

export const { Provider, Consumer } = CustomerContext

const CustomerProvider = ({ children }) => {
    const customer = getSubdomain() || 'poyi'
    setCustomer(customer)

    return <Provider value={customer}>{children}</Provider>
}

export default CustomerProvider
