const ZHTW = {
    'Member Register': '会员注册',
    Phone: '电话号码',
    Name: '名称',
    'First Name': '名',
    'Last Name': '姓',
    'Email address': '信箱',
    Birthday: '生日',
    Address: '地址',
    Gender: '性别',
    'Gender.male': '男',
    'Gender.female': '女',
    'Gender.other': '其他',
    'Gender.unknown': '未填写',

    // Action
    'Log In': '登入',
    Register: '注册',
    Submit: '提交',
    Actions: '动作',
    Cancel: '取消',
    Save: '储存',

    // Valid
    'is required': '{{ name }} 为必填',
    'Must be a valid email': '必须为Email',
    'Must be at least 6 characters': '最少为六位英数字',

    // Message
    'Please scan QRCode at store to register': '请于门市扫QRCode注册',
    'Register success': '注册成功',
    'Thank for your register': '欢迎您加入Memberline',
    'register done hint': '您现在可以正式使用会员活动咯',
    'Cannot connect to server.': '無法連線到伺服器',
    'Line login fail. Please retry later.': 'Line登入失敗，請稍後再試',
}
export default ZHTW
