import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from './components/Loading';
import PrivateRoute from './components/PrivateRoute';
import GuestRoute from './components/GuestRoute';

import CustomerProvider from './stores/customer';
import BranchProvider from './stores/branch';
import LiffProvider from './providers/LiffProvider';

const QRCode = lazy(() => import('./pages/QRCode'));
const Register = lazy(() => import('./pages/Register'));
const Home = lazy(() => import('./pages/Home'));
const ChopsDetail = lazy(() => import('./pages/ChopsDetail'));
const ChopsRecord = lazy(() => import('./pages/ChopsRecord'));
const OrderRecord = lazy(() => import('./pages/OrderRecord'));
const PrepaidcardRecord = lazy(() => import('./pages/PrepaidcardRecord'));
const InformationEdit = lazy(() => import('./pages/InformationEdit'));
const Login = lazy(() => import('./pages/Login'));
const ForgetPassword = lazy(() => import('./pages/ForgetPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const LineLogin = lazy(() => import('./pages/LineLogin'));

function AppRoute() {
    return (
        <CustomerProvider>
            <BranchProvider>
                <LiffProvider>
                    <React.Suspense fallback={<Loading />}>
                        <Switch>
                            <Route path="/login/line" component={LineLogin} />
                            <GuestRoute path="/login" component={Login} />
                            <GuestRoute
                                path="/forgetPassword"
                                component={ForgetPassword}
                            />
                            <GuestRoute
                                path="/resetPassword"
                                component={ResetPassword}
                            />
                            <Route path="/register/qrcode" component={QRCode} />
                            <Route path="/register" component={Register} />
                            <PrivateRoute path="/home" component={Home} />
                            <PrivateRoute
                                path="/chops/detail"
                                component={ChopsDetail}
                            />
                            <PrivateRoute
                                path="/chops/records"
                                component={ChopsRecord}
                            />
                            <PrivateRoute
                                path="/order/records"
                                component={OrderRecord}
                            />
                            <PrivateRoute
                                path="/prepaidcard/records"
                                component={PrepaidcardRecord}
                            />
                            <PrivateRoute
                                path="/information/edit"
                                component={InformationEdit}
                            />
                            <PrivateRoute exact path="/" component={Home} />
                        </Switch>
                    </React.Suspense>
                </LiffProvider>
            </BranchProvider>
        </CustomerProvider>
    );
}

export default AppRoute;
