import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LiffProvider as BaseLiffProvider } from 'react-liff'
import { getLiffId } from '../services/lineService'
import { getCustomer } from '../utils/customer'
import liff from '@line/liff'

const ContextStore = React.createContext({})
export const useLiff = () => useContext(ContextStore)

const TEST_LIFF_ID = '1655867468-lZL0orYb'

const LiffProvider = ({ children, customer }) => {
    const [liffId, setLiffId] = useState('')
    const [ready, setReady] = useState(false)

    useEffect(() => {
        const fetchLiffId = async () => {
            try {
                const customerLiffId = await getLiffId(getCustomer())
                const { line_liff_id: lineLiffId } = customerLiffId.data.data
                setLiffId(lineLiffId || TEST_LIFF_ID)
                await liff.init({
                    liffId: lineLiffId,
                })
                setReady(true)
            } catch (e) {
                console.log(e)
                setLiffId(TEST_LIFF_ID)
            }
        }
        fetchLiffId()
    }, [customer])

    return (
        <ContextStore.Provider value={{ liffId: liffId, liff: liff, ready }}>
            <BaseLiffProvider liffId={liffId}>{children}</BaseLiffProvider>
        </ContextStore.Provider>
    )
}

LiffProvider.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.object,
}

export default LiffProvider
