export const setCustomer = (customer) => {
    sessionStorage.setItem('customer', customer)
}

export const removeCustomer = () => {
    sessionStorage.removeItem('customer')
}

export const getCustomer = () => sessionStorage.getItem('customer')

export const setBranch = (branch) => {
    sessionStorage.setItem('branch', branch)
}

export const removeBranch = () => {
    sessionStorage.removeItem('branch')
}

export const getBranch = () => sessionStorage.getItem('branch')
