const ZHTW = {
    'Member Register': 'Member Register',
    Phone: 'Phone',
    Name: 'Name',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Email address': 'Email address',
    Birthday: 'Birthday',
    Address: 'Address',
    Gender: 'Gender',
    'Gender.male': 'Male',
    'Gender.female': 'Female',
    'Gender.other': 'Other',
    'Gender.unknown': 'Unknown',

    // Action
    'Log In': 'Log In',
    Register: 'Register',
    Submit: 'Submit',
    Actions: 'Actions',
    Cancel: 'Cancel',
    Save: 'Save',

    // Valid
    'is required': '{{ name }} is required',
    'Must be a valid email': 'Must be a valid email',
    'Must be at least 6 characters': 'Must be at least 6 characters',

    // Message
    'Please scan QRCode at store to register':
        'Please scan QRCode at store to register',
    'Register success': 'Register success',
    'Thank for your register': 'Thank for your register',
    'register done hint': 'Please enjoy it.',
    'Cannot connect to server.': 'Cannot connect to server.',
}
export default ZHTW
