import moment from 'moment'

export const saveToken = (token) => {
    localStorage.setItem('MEMBERLINE_TOKEN', token)
}

export const removeToken = () => {
    localStorage.removeItem('MEMBERLINE_TOKEN')
}

export const getToken = () => localStorage.getItem('MEMBERLINE_TOKEN')

export const saveExpiredTime = (token) => {
    localStorage.setItem('MEMBERLINE_TOKEN_EXPIRED_AT', token)
}

export const removeExpiredTime = () => {
    localStorage.removeItem('MEMBERLINE_TOKEN_EXPIRED_AT')
}

export const getExpiredTime = () =>
    localStorage.getItem('MEMBERLINE_TOKEN_EXPIRED_AT')

export const tokenExpired = () => {
    const expiredTime = getExpiredTime()
    if (!expiredTime) {
        return true
    }
    if (moment(expiredTime).isAfter(moment())) {
        return true
    }
    return false
}
