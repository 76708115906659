import React from 'react'
import { CircularProgress } from '@material-ui/core'

import './style.scss'

function LoadingScreen() {
    return (
        <div className='loading-div'>
            <CircularProgress
                variant='indeterminate'
                style={{ color: '#f06235' }}
                disableShrink
            />
        </div>
    )
}

export default LoadingScreen
