const ZHTW = {
    'Member Register': '會員註冊',
    Phone: '電話號碼',
    Name: '名稱',
    'First Name': '名',
    'Last Name': '姓',
    'Email address': '信箱',
    Birthday: '生日',
    Address: '地址',
    Gender: '性別',
    'Gender.male': '男',
    'Gender.female': '女',
    'Gender.other': '其他',
    'Gender.unknown': '未填寫',
    Password: '密碼',
    'Password Confirm': '確認密碼',
    'Card Carrier No': '會員載具卡號',

    'Chops Detail': '我的會員點數',
    'Chops Records': '紅利點數紀錄',
    'Order Records': '消費歷史紀錄',
    'Prepaidcard Records': '儲值卡歷史紀錄',
    'Information Edit': '會員資料修改',
    Time: '時間',
    Branch: '門市',
    Chops: '紅利點數',
    Amount: '金額',
    Balance: '金額',

    // Action
    'Log In': '登入',
    'Log Out': '登出',
    Register: '註冊',
    Submit: '提交',
    Actions: '動作',
    Cancel: '取消',
    Save: '儲存',

    // Valid
    'is required': '必填',
    'Must be a valid email': '必須為Email',
    'Must be at least 6 characters': '最少為六位英數字',
    'Passwords must match': '密碼不符',

    ALL: '全部',
    ADD_RECORD: '紅利累積',
    CONSUMED_RECORD: '紅利兌換',
    TOPUP_RECORD: '加值紀錄',
    PAYMENT_RECORD: '扣款紀錄',

    // Message
    Unauthenticated: '帳號密碼錯誤',
    'Please scan QRCode at store to register': '請於門市掃QRCode註冊',
    'Register success': '註冊成功',
    'Update success': '更新資料成功',
    'Thank for your register': '歡迎您加入Memberline',
    'register done hint': '您現在可以正式使用會員活動',
    'Customer is not exist or expired': '請於門市掃QRCode進行登入',
    'Cannot connect to server.': '無法連線到伺服器',
    'Load Member Data Failed': '讀取資料失敗，請稍後再試',
    'Line login fail. Please retry later.': 'Line登入失敗，請稍後再試',
    'The phone field is required.': '電話號碼為必填',
    'Token invalidated': '登入逾時！請重新登入',
    "Member's phone not exist": '帳號密碼錯誤',
    'Thanks for your using. Already send reset password link to your phone':
        '感謝您使用 Memberline，重置密碼信已寄至您的手機',
    'Token not exist': '驗證碼錯誤',
    'Password already reset. Please login with your new password.':
        '密碼已重置，請以新密碼登入',
    'The token field is required.': '請由重置密碼信進行重置',
};
export default ZHTW;
