import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import {
    getToken,
    tokenExpired,
    removeToken,
    removeExpiredTime,
} from '../../utils/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = getToken();

    if (!token || !tokenExpired()) {
        removeToken();
        removeExpiredTime();
        return (
            <Redirect
                to={{
                    pathname: '/login',
                }}
            />
        );
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
